<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card min-height="500">
      <v-card-title class="pos-r">
        提單
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <SizeBox height="48" />
        <v-form ref="form">
          <v-card
            outlined
            :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
          >
            <v-card-text>
              <v-text-field
                rounded
                :value="localData.name"
                label="商戶名稱"
                disabled
                outlined
              ></v-text-field>
              <c-autocomplete
                rounded
                label="選擇通道"
                v-model="form.collect_channel_id"
                :rules="[$rules.required]"
                :api="getAvailableCollectChannels"
                outlined
              ></c-autocomplete>
              <v-text-field
                rounded
                v-model.number="form.amount"
                label="金額"
                :rules="[$rules.requiredNum]"
                type="number"
                outlined
              ></v-text-field>
            </v-card-text>
          </v-card>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          rounded
          color="Secondary100"
          depressed
          large
          @click="close"
          :disabled="loading"
        >
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="primary"
          dark
          depressed
          large
          :loading="loading"
          :disabled="isUnavailable"
          @click="submit"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getAvailableCollectChannels } from '@/api/merchants';
import schema from './schema';
import { mockOrder } from '@/api/collectOrders';
import CAutocomplete from '@/components/Globals/CAutocomplete.vue';

export default {
  components: { CAutocomplete },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({
        /**
         * mode: String, // new, edit
         */
      })
    }
  },

  data() {
    return {
      loading: false,
      channelOptions: [],
      localData: {},
      isUnavailable: false,
      form: {
        merchant_id: '',
        collect_channel_id: '',
        amount: 0
      }
    };
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    },
    isUnavailable(v) {
      v && this.$toast.error('通道未開啟或尚未建立');
    }
  },

  methods: {
    init() {
      this.$set(this, 'localData', { ...schema, ...this.passData });
      this.form.merchant_id = this.localData.id;
      this.$refs.form && this.$refs.form.resetValidation();
      this.getAvailableCollectChannels();
    },
    reset() {
      this.$set(this, 'localData', {});
      this.$refs.form && this.$refs.form.reset();
      this.loading = false;
      this.isUnavailable = false;
    },
    close() {
      this.$emit('close');
    },
    async getAvailableCollectChannels(payload) {
      const channel = await getAvailableCollectChannels({
        merchant_id: this.passData.id,
        ...payload
      });

      if (Array.isArray(channel) && !channel.length) {
        this.isUnavailable = true;
      }

      return channel;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;

      const { error } = await mockOrder({
        ...this.form
      });

      if (!error) {
        this.$toast('提單成功');
        this.close();
        this.$emit('save');
      } else {
        this.$toast.error(`提單失敗\n${error.message}`);
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
