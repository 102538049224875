<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card min-height="500">
      <v-card-title class="pos-r">
        手續費設定
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <SizeBox height="48" />
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <v-text-field
                rounded
                label="商戶編號"
                :value="localData.serial"
                disabled
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                rounded
                label="目前可用額度"
                :value="localData.prepaid_fee"
                class="remove-append-margin"
                readonly
                outlined
              >
                <template #append>
                  <v-btn
                    rounded
                    color="primary"
                    dark
                    depressed
                    class="ma-0 m-w100p"
                    @click="isViewHistory = true"
                  >
                    查看紀錄
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                rounded
                label="調整手續費額度"
                v-model.number="form.change_prepaid_fee"
                :rules="[$rules.required]"
                outlined
                class="remove-append-margin"
              >
                <template #append v-if="!$vuetify.breakpoint.smAndDown">
                  <v-btn rounded @click="addAmount(100)">+100</v-btn>
                  <div class="mr-1"></div>
                  <v-btn rounded @click="addAmount(1000)">+1000</v-btn>
                  <div class="mr-1"></div>
                  <v-btn rounded @click="addAmount(10000)">+10000</v-btn>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                rounded
                label="備註"
                v-model="form.note"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          rounded
          color="Secondary100"
          depressed
          large
          @click="close"
          :disabled="loading"
        >
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="primary"
          dark
          depressed
          large
          :loading="loading"
          @click="submit"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="isViewHistory" scrollable>
      <v-card>
        <v-card-title>
          凍結紀錄
          <v-spacer></v-spacer>
          <v-icon @click="isViewHistory = false">mdi-close</v-icon>
        </v-card-title>

        <v-card-text>
          <DataTable
            :fields="history.header"
            :list="history.items"
            :total="history.total"
            :loading="history.loading"
            :replace="['created_at|formatTime']"
            @get-list="fetchHistory"
            page-align
          ></DataTable>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import {
  increaseMerchantPrepaidFee,
  getMerchantPrepaidFeeLogList
} from '@/api/merchants';
import schema from './schema';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({
        /**
         * mode: String, // new, edit
         */
      })
    }
  },

  data() {
    return {
      isViewHistory: false,
      history: {
        loading: false,
        header: [
          // { text: '', value: 'id'},
          { text: '手續費額度', value: 'prepaid_fee' },
          { text: '變更值', value: 'change_prepaid_fee' },
          { text: '建立日期', value: 'created_at' },
          { text: '備註', value: 'note' }
        ],
        page: 1,
        total: 0,
        limit: 20,
        items: []
      },

      isFetching: false,
      loading: false,
      localData: {},
      pickingIdx: 0,
      form: {
        merchant_id: '',
        change_prepaid_fee: 0,
        note: ''
      }
    };
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    },
    isViewHistory(v) {
      this.fetchHistory();
    }
  },

  methods: {
    init() {
      this.$set(this, 'localData', {
        ...this.localData,
        ...schema,
        ...this.passData
      });
      this.$set(this, 'form', { ...this.form, ...schema, ...this.passData });
      this.form.merchant_id = this.localData.id;
      this.$refs.form && this.$refs.form.resetValidation();
    },
    reset() {
      const originData = this.$options.data();
      Object.keys(originData).forEach((key) => {
        this.$set(this.$data, key, originData[key]);
      });
      this.$refs.form.reset();
    },
    close() {
      this.$emit('close');
    },
    addAmount(v) {
      this.form.change_prepaid_fee += v;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;

      const { error } = await increaseMerchantPrepaidFee({
        ...this.form
      });

      if (!error) {
        this.$toast('保存成功');
        this.$emit('save');
        this.close();
      } else {
        this.$toast.error('保存失敗');
      }

      this.loading = false;
    },

    async fetchHistory() {
      this.history.loading = true;
      const History = await getMerchantPrepaidFeeLogList({
        filter: { merchant_id: this.localData.id }
      });

      if (!History.error) {
        this.history.total = History.total;
        this.history.page = History.page;
        this.$set(this.history, 'items', History.items);
      }

      this.history.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.remove-append-margin {
  ::v-deep.v-input__append-inner {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>
