<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card min-height="500">
      <v-card-title class="pos-r">
        查看密鑰
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-window v-model="step">
          <SizeBox height="48" />
          <v-window-item :value="0">
            <v-form ref="form">
              <Async
                :loading="loading"
                :class="loading ? ['w100p', 'flex-center'] : null"
              >
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      rounded
                      label="輸入管理員密碼"
                      placeholder="輸入密碼"
                      v-model="password"
                      hint="先驗證為管理員後，才可查看商戶密鑰"
                      :rules="[$rules.required]"
                      :type="isHidePassword ? 'text' : 'password'"
                      :append-icon="isHidePassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="isHidePassword = !isHidePassword"
                      persistent-hint
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </Async>
            </v-form>
          </v-window-item>
          <v-window-item :value="1">
            <SizeBox height="12" />
            <v-text-field
              rounded
              label="商戶密鑰"
              :value="api_secret"
              @click="copy(api_secret)"
              readonly
              outlined
            >
              <template #append>
                <v-btn rounded depressed @click="copy(api_secret)">複製</v-btn>
              </template>
            </v-text-field>

            <v-text-field
              rounded
              label="谷歌密鑰"
              :value="otp_secret"
              @click="copy(otp_secret)"
              readonly
              outlined
            >
              <template #append>
                <v-btn rounded depressed @click="copy(otp_secret)">複製</v-btn>
              </template>
            </v-text-field>
          </v-window-item>
        </v-window>
      </v-card-text>

      <v-card-actions>
        <v-btn
          rounded
          color="Secondary100"
          depressed
          large
          @click="close"
          :disabled="loading"
        >
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          v-if="step === 0"
          color="primary"
          dark
          depressed
          large
          @click="submit"
          :loading="loading"
        >
          下一步
        </v-btn>
        <v-btn
          rounded
          v-if="step === 1"
          color="primary"
          dark
          depressed
          large
          @click="close"
        >
          確認
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { checkSecrets } from '@/api/merchants';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({
        /**
         * mode: String, // new, edit
         */
      })
    }
  },

  data() {
    return {
      loading: false,
      isHidePassword: false,
      password: '',
      step: 0,
      api_secret: '',
      otp_secret: ''
    };
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    }
  },

  methods: {
    init() {
      this.step = 0;
      this.$refs.form && this.$refs.form.reset();
    },
    reset() {
      this.loading = false;
      this.password = '';
      this.step = 0;
      this.$refs.form && this.$refs.form.reset();
    },
    close() {
      this.$emit('close');
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;

      const { otp_secret, api_secret, error } = await checkSecrets({
        password: this.password,
        merchant_id: this.passData.id
      });

      if (!error) {
        this.otp_secret = otp_secret;
        this.api_secret = api_secret;
        this.step++;
      } else {
        this.$toast.error('密碼錯誤');
      }

      this.loading = false;
    },
    copy(text) {
      navigator.clipboard.writeText(text);

      this.$toast('已成功複製');
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input__append-inner {
    margin: auto !important;
  }
}
</style>
