<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card min-height="500">
      <v-card-title class="pos-r">
        代收風控
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <SizeBox height="48" />
        <v-form ref="form">
          <v-card
            outlined
            :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
          >
            <v-card-text>
              <v-select
                rounded
                :items="modeOptions"
                v-model="form.mode"
                :rules="[$rules.required]"
                label="風控模式"
                outlined
              ></v-select>

              <v-expand-transition>
                <v-card outlined v-if="form.mode === 'self_define'">
                  <v-card-text>
                    <v-text-field
                      rounded
                      v-model.number="form.daily_max_amt"
                      label="當天總金額"
                      :rules="[$rules.requiredNum]"
                      type="number"
                      outlined
                    ></v-text-field>
                    <v-text-field
                      rounded
                      v-model.number="form.single_min_amt"
                      label="單筆最小金額"
                      :rules="[$rules.requiredNum]"
                      type="number"
                      outlined
                    ></v-text-field>
                    <v-text-field
                      rounded
                      v-model.number="form.single_max_amt"
                      label="單筆最大金額"
                      :rules="[$rules.requiredNum]"
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-card-text>
                </v-card>
              </v-expand-transition>
            </v-card-text>
          </v-card>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          rounded
          color="Secondary100"
          depressed
          large
          @click="close"
          :disabled="loading"
        >
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="primary"
          dark
          depressed
          large
          :loading="loading"
          @click="submit"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { addCollectRule, editCollectRule } from '@/api/merchants';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({
        /**
         * mode: String, // new, edit
         */
      })
    }
  },

  data() {
    return {
      loading: false,
      modeOptions: [
        {
          text: '系統規則',
          value: 'inherit_system'
        },
        {
          text: '自行設定',
          value: 'self_define'
        }
      ],
      form: {
        merchant_id: '',
        single_min_amt: 0,
        single_max_amt: 0,
        daily_max_amt: 0,
        scope: '',
        mode: '' // 'inherit_system' or 'self_define'
      }
    };
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    }
  },

  methods: {
    init() {
      this.$refs.form && this.$refs.form.reset();
      this.form = {
        merchant_id: this.passData.collect_rule.merchant_id,
        single_min_amt: Number(this.passData.collect_rule.single_min_amt),
        single_max_amt: Number(this.passData.collect_rule.single_max_amt),
        daily_max_amt: Number(this.passData.collect_rule.daily_max_amt),
        scope: this.passData.collect_rule.scope,
        mode: this.passData.collect_rule.mode
      };
    },
    reset() {
      this.$refs.form && this.$refs.form.reset();
      this.loading = false;
      this.form = {
        merchant_id: '',
        single_min_amt: 0,
        single_max_amt: 0,
        daily_max_amt: 0,
        scope: '',
        mode: ''
      };
    },
    close() {
      this.$emit('close');
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;

      const { error } = await editCollectRule(this.form);

      if (!error) {
        this.$toast('保存成功');
        this.$emit('save');
        this.close();
      } else {
        this.$toast.error('保存失敗');
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
