<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card min-height="500">
      <v-card-title class="pos-r">
        費率設定
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <SizeBox height="48" />
        <p>
          時間設定為 24 小時制（設定範圍為 0 點至 23
          點），若起始/結束時間皆不輸入，則表示該費率設定為預設值。
        </p>
        <v-form ref="form">
          <template v-for="(setting, i) in form.merchant_fee_settings">
            <FeeSetRow
              :key="`setting${i}${setting.id}`"
              v-model="setting.value"
              :feeSettingOptions="feeSettingOptions"
              @delete="deleteRow(i)"
            />

            <v-divider
              :key="`setting${i}${setting.id}-divider`"
              class="mb-5"
            ></v-divider>
          </template>
        </v-form>

        <v-tooltip right>
          <template #activator="{ on }">
            <v-btn fab v-on="on" @click="addRow" small>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>新增時段費率</span>
        </v-tooltip>
      </v-card-text>

      <v-card-actions>
        <v-btn
          rounded
          color="Secondary100"
          depressed
          large
          @click="close"
          :disabled="loading"
        >
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="primary"
          dark
          depressed
          large
          :loading="loading"
          @click="submit"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  editMerchantFeeSettings,
  getMerchantFeeSettings
} from '@/api/merchants';
import schema from './schema';
import FeeSetRow from './FeeForm/FeeSetRow.vue';
import { getCollectChannelTypes } from '@/api/collectChannels';

export default {
  components: { FeeSetRow },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({
        /**
         * mode: String, // new, edit
         */
      })
    }
  },

  data() {
    return {
      loading: false,
      localData: {},
      pickingIdx: 0,
      feeSettingOptions: [],
      feeSettings: [
        /**
         * {
         *   id: <String>,
         *   merchant_id: <String>,
         *   fee_percent: <Number>,
         *   collect_channel_type: {
         *     name: <String>,
         *     code: <String>
         *   },
         *   created_at: <Timestamp>,
         *   updated_at: <Timestamp>
         * }
         */
      ],
      channelName: '',
      form: {
        merchant_id: '',
        merchant_fee_settings: [
          /**
           * {
           *   id: '',
           *   fee_percent: 0
           * }
           */
        ]
      }
    };
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    }
  },

  methods: {
    init() {
      this.$set(this, 'localData', { ...schema, ...this.passData });
      this.form.merchant_id = this.localData.id;
      this.$refs.form && this.$refs.form.reset();
      this.fetchData();
    },
    reset() {
      this.$refs.form && this.$refs.form.reset();
      this.feeSettings = [];
      this.form.merchant_fee_settings = [];
    },
    close() {
      this.$emit('close');
    },
    async fetchData() {
      this.isFetching = true;

      const Types = await getCollectChannelTypes();
      this.feeSettingOptions = Types.map(({ id, name }) => ({
        text: name,
        value: id
      }));

      const Settings = await getMerchantFeeSettings({
        merchant_id: this.localData.id
      });

      if (!Settings.error) {
        const feeSettings = Settings.merchantFeeSettings;
        this.form.merchant_id = this.localData.id;
        this.$set(
          this,
          'feeSettings',
          Array.isArray(feeSettings) ? feeSettings : []
        );
        this.$set(
          this.form,
          'merchant_fee_settings',
          feeSettings.map((el) => ({
            id: el.id,
            value: {
              collect_channel_type_id: el.collect_channel_type_id,
              feePercent: Number(el.fee_percent),
              startHour: el.start_hour,
              endHour: el.end_hour
            }
          }))
        );
        if (Settings.length) this.pickingIdx = 0;
      }
      this.isFetching = false;
    },
    addRow() {
      this.form.merchant_fee_settings.push({
        value: {
          startHour: null,
          endHour: null,
          feePercent: 0
        }
      });
    },
    deleteRow(idx) {
      this.form.merchant_fee_settings.splice(idx, 1);
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      const parsedSettings = this.form.merchant_fee_settings.map((setting) => {
        const output = {
          id: setting.id,
          collect_channel_type_id: setting.value.collect_channel_type_id,
          fee_percent: Number(setting.value.feePercent),
          start_hour:
            setting.value.startHour === '' || setting.value.startHour === null
              ? null
              : Number(setting.value.startHour),
          end_hour:
            setting.value.endHour === '' || setting.value.endHour === null
              ? null
              : Number(setting.value.endHour)
        };
        if (!setting.id) delete output.id;

        return output;
      });

      const { error } = await editMerchantFeeSettings({
        merchant_id: this.form.merchant_id,
        merchantFeeSettings: parsedSettings
      });

      if (!error) {
        this.$toast('保存成功');
        this.close();
      } else {
        this.$toast.error('保存失敗');
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
