<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card min-height="500">
      <v-card-title class="pos-r">
        代付風控
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <SizeBox height="48" />
        <v-card
          outlined
          :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
        >
          <v-card-text>
            <v-select
              rounded
              :items="modeOptions"
              v-model="form.mode"
              label="風控模式"
              outlined
            ></v-select>

            <v-expand-transition>
              <v-card outlined v-if="form.mode === 'self_define'">
                <v-card-text>
                  <v-form ref="form">
                    <v-text-field
                      rounded
                      v-model.number="form.single_min_amt"
                      label="單筆最小金額"
                      type="number"
                      outlined
                    ></v-text-field>
                    <v-text-field
                      rounded
                      v-model.number="form.single_max_amt"
                      label="單筆最大金額"
                      type="number"
                      outlined
                    ></v-text-field>
                    <v-text-field
                      rounded
                      v-model.number="form.daily_pay_times_limit_per_card"
                      label="每日子帳戶提領次數上限"
                      type="number"
                      outlined
                    ></v-text-field>
                    <v-select
                      rounded
                      :items="feeModeOptions"
                      v-model="form.fee_mode"
                      label="手續費計算方式"
                      outlined
                    ></v-select>
                    <v-card
                      outlined
                      :color="
                        $vuetify.breakpoint.smAndDown ? 'transparent' : null
                      "
                    >
                      <v-card-text>
                        <v-text-field
                          rounded
                          v-if="form.fee_mode === 'fee'"
                          v-model.number="form.fee"
                          label="單筆手續費"
                          :rules="[checkFloat]"
                          type="number"
                          outlined
                        ></v-text-field>
                        <v-text-field
                          rounded
                          v-else-if="form.fee_mode === 'fee_percent'"
                          v-model.number="form.fee_percent"
                          label="單筆費率（%）"
                          :rules="[checkFloat]"
                          type="number"
                          outlined
                        ></v-text-field>
                      </v-card-text>
                    </v-card>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-actions>
        <v-btn
          rounded
          color="Secondary100"
          depressed
          large
          @click="close"
          :disabled="loading"
        >
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="primary"
          dark
          depressed
          large
          :loading="loading"
          @click="submit"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { editPayRule } from '@/api/merchants';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({
        /**
         * mode: String, // new, edit
         */
      })
    }
  },

  data() {
    return {
      loading: false,
      modeOptions: [
        {
          text: '系統規則',
          value: 'inherit_system'
        },
        {
          text: '自行設定',
          value: 'self_define'
        }
      ],
      feeModeOptions: [
        {
          text: '單筆手續費',
          value: 'fee'
        },
        {
          text: '單筆費率',
          value: 'fee_percent'
        }
      ],

      form: {
        merchant_id: '',
        daily_max_amt: 0,
        single_min_amt: 0,
        single_max_amt: 0,
        fee_mode: 'fee', // 'fee_percent' or 'fee'
        fee_percent: 0,
        fee: 0,
        scope: 'merchant',
        daily_pay_times_limit_per_card: 0,
        mode: 'inherit_system' // 'inherit_system' or 'self_define'
      }
    };
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    }
  },

  methods: {
    init() {
      this.$refs.form && this.$refs.form.reset();
      this.form = {
        merchant_id: this.passData.pay_rule.merchant_id,
        single_min_amt: Number(this.passData.pay_rule.single_min_amt || 0),
        single_max_amt: Number(this.passData.pay_rule.single_max_amt || 0),
        fee_mode: this.passData.pay_rule.fee_mode,
        fee_percent: Number(this.passData.pay_rule.fee_percent || 0),
        fee: Number(this.passData.pay_rule.fee || 0),
        scope: this.passData.pay_rule.scope,
        daily_pay_times_limit_per_card: Number(
          this.passData.pay_rule.daily_pay_times_limit_per_card || 0
        ),
        mode: this.passData.pay_rule.mode
      };
    },
    reset() {
      this.$refs.form && this.$refs.form.reset();
      this.loading = false;
      this.form = {
        merchant_id: '',
        single_min_amt: 0,
        single_max_amt: 0,
        fee_mode: 'fee', // 'fee_percent' or 'fee'
        fee_percent: 0,
        fee: 0,
        scope: 'merchant',
        mode: 'inherit_system' // 'inherit_system' or 'self_define'
      };
    },
    close() {
      this.$emit('close');
    },

    checkFloat(v) {
      const float = `${v}`.split('.')[1];

      if (float) {
        return float.length <= 2 ? true : '小數點後至多兩位';
      }

      return true;
    },
    async submit() {
      if (this.form.mode === 'self_define' && !this.$refs.form.validate())
        return;
      this.loading = true;

      const { error } = await editPayRule(this.form);

      if (!error) {
        this.$toast('保存成功');
        this.$emit('save');
        this.close();
      } else {
        this.$toast.error('保存失敗');
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
