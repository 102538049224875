<template>
  <v-row no-gutters class="mb-7">
    <v-col cols="12" class="py-0 px-1">
      <v-select
        rounded
        label="通道類型"
        :items="feeSettingOptions"
        v-model="feeSettingChannelType"
        @change="(v) => handleChange({ collect_channel_type_id: v })"
        mandatory
        outlined
      ></v-select>
    </v-col>

    <v-col cols="6" md="4" class="py-0 px-1" order="1">
      <v-text-field
        rounded
        class="remove-append-margin mb-2"
        label="費率 (%)"
        :value="value.feePercent"
        @change="(v) => handleChange({ feePercent: v < 0 ? 0 : v })"
        min="0"
        type="number"
        outlined
        :rules="[checkFloat]"
      ></v-text-field>
    </v-col>

    <v-col cols="6" md="3" class="py-0 px-1" order="2">
      <v-text-field
        label="開始時間"
        rounded
        outlined
        hide-details
        clearable
        min="0"
        max="23"
        type="number"
        :value="value.startHour"
        @change="
          (v) => handleChange({ startHour: v < 0 ? 0 : v > 23 ? 23 : v })
        "
      ></v-text-field>
    </v-col>
    <v-col cols="6" md="3" class="py-0 px-1" order="4">
      <v-text-field
        label="結束時間"
        rounded
        outlined
        hide-details
        clearable
        min="0"
        max="23"
        type="number"
        :value="value.endHour"
        @change="(v) => handleChange({ endHour: v < 0 ? 0 : v > 23 ? 23 : v })"
      ></v-text-field>
    </v-col>

    <v-col
      cols="6"
      md="2"
      class="py-0 px-1 d-flex align-center justify-end justify-md-center"
      order="3"
      order-md="5"
    >
      <v-btn fab @click="$emit('delete')" small>
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        collect_channel_type_id: '',
        startHour: null,
        endHour: null,
        feePercent: 0
      })
    },
    feeSettingOptions: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      feeSettingChannelType: this.value.collect_channel_type_id
    };
  },

  methods: {
    handleChange({
      collect_channel_type_id = this.value.collect_channel_type_id,
      startHour = this.value.startHour,
      endHour = this.value.endHour,
      feePercent = this.value.feePercent
    }) {
      this.$emit('input', {
        collect_channel_type_id,
        startHour,
        endHour,
        feePercent
      });
    },

    checkFloat(v) {
      const float = `${v}`.split('.')[1];

      if (float) {
        return float.length <= 2 ? true : '小數點後至多兩位';
      }

      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.remove-append-margin {
  ::v-deep.v-input__append-inner {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>