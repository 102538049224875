export default {
  id: '',
  account: '',
  name: '',
  serial: '',
  merchant_type_id: '',
  merchant_id: '',
  amt: 0,
  status: true,
  enabled_collect: false,
  enabled_pay_merchant: false,
  enabled_pay_api: false,
  enabled_whitelisted_login_ips: false,
  whitelisted_login_ips: [],
  whitelisted_collect_ips: [],
  whitelisted_collect_domains: [],
  whitelisted_pay_ips: [],
  whitelisted_pay_domains: []
};
