<template>
  <!-- eslint-disable  -->
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card>
      <v-card-title class="pos-r">
        調整資金
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <SizeBox height="48" />
        <v-row>
          <v-col cols="12">
            <v-text-field
              rounded
              label="商戶編號"
              :value="localData.serial"
              disabled
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              rounded
              label="可用餘額"
              placeholder="輸入可用餘額"
              v-model.number="localData.amt"
              disabled
              outlined
              :loading="isFetching"
            ></v-text-field>
          </v-col>

          <v-col cols="8" sm="4">
            <v-text-field
              rounded
              label="凍結中餘額"
              v-model="localData.frozen_amt"
              disabled
              outlined
              :loading="isFetching"
            ></v-text-field>
          </v-col>

          <v-col cols="4" sm="2">
            <v-btn
              rounded
              color="primary"
              dark
              depressed
              large
              class="ma-0 m-w100p"
              @click="isViewFreezeHistory = true"
            >
              凍結紀錄
            </v-btn>
          </v-col>
        </v-row>

        <p>帳戶設定</p>
        <v-form ref="form">
          <v-tabs grow v-model="mode">
            <v-tab value="increase">餘額增加</v-tab>
            <v-tab value="decrease">餘額減少</v-tab>
            <v-tab value="freeze">凍結資金</v-tab>
          </v-tabs>

          <v-card
            outlined
            :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
          >
            <v-card-text>
              <v-text-field
                rounded
                label="金額"
                v-model.number="form.amount"
                type="number"
                outlined
              ></v-text-field>

              <v-text-field
                rounded
                label="調整說明"
                v-model="form.note"
                :rules="[$rules.required]"
                outlined
              ></v-text-field>

              <v-expand-transition>
                <c-date-picker
                  v-if="mode === 2 /* v-tab value to freeze */"
                  label="預期解凍時間"
                  v-model="form.to_unfreeze_at"
                  :rules="[$rules.required]"
                  outlined
                  with-time
                  no-range
                  future-only
                />
              </v-expand-transition>

              <v-text-field
                rounded
                label="谷歌驗證碼"
                v-model="form.otp_code"
                :rules="[$rules.required]"
                outlined
              ></v-text-field>
            </v-card-text>
          </v-card>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn rounded color="Secondary100" depressed large @click="close">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="primary"
          dark
          depressed
          large
          @click="submit"
          :loading="loading"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="isViewFreezeHistory" scrollable>
      <v-card>
        <v-card-title>
          凍結紀錄
          <v-spacer></v-spacer>
          <v-icon @click="isViewFreezeHistory = false">mdi-close</v-icon>
        </v-card-title>

        <v-card-text>
          <DataTable
            :fields="freezeHistory.header"
            :list="freezeHistory.items"
            :total="freezeHistory.total"
            :loading="freezeHistory.loading"
            :labels="{
              status: [{ text: '凍結', value: 'frozen', color: 'Primary400' }]
            }"
            :replace="[
              'to_unfreeze_at|formatTime',
              'unfroze_at|formatTime',
              'created_at|formatTime'
            ]"
            @get-list="fetchFreezeHistory"
            page-align
          ></DataTable>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { addCapitalLog, checkCapital } from '@/api/merchants';
import { getFrozenOrders } from '@/api/capitalLogs';
import schema from './schema';

/**
 * frozen history schema
 * [
 *   {
 *    id: <String>,
 *    order_no: <String>,
 *    status: <String>,
 *    amt: <Number>,
 *    frozen_note: <String>,
 *    unfrozen_note: <String>,
 *    to_unfreeze_at: <Timestamp>,
 *    unfroze_at: <Timestamp>,
 *    created_at: <Timestamp>
 *   }
 * ]
 *
 */

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({
        /**
         * freezingFund: Number
         */
      })
    }
  },

  data() {
    return {
      isViewFreezeHistory: false,
      freezeHistory: {
        loading: false,
        header: [
          // { text: '', value: 'id'},
          { text: '訂單編號', value: 'order_no' },
          { text: '狀態', value: 'status' },
          { text: '金額', value: 'amt' },
          { text: '凍結說明', value: 'frozen_note' },
          { text: '解凍說明', value: 'unfrozen_note' },
          { text: '預期解凍時間', value: 'to_unfreeze_at' },
          { text: '解凍時間', value: 'unfroze_at' },
          { text: '建立日期', value: 'created_at' }
        ],
        page: 1,
        total: 0,
        limit: 20,
        items: []
      },

      isFetching: true,
      loading: false,

      mode: 0,
      localData: {
        amt: 0,
        frozen_amt: 0
      },
      form: {
        amount: 0,
        note: '',
        to_unfreeze_at: '',
        otp_code: ''
      }
    };
  },

  computed: {
    /**
     * @return  'manual_add', 'manual_reduce', 'freeze'
     */
    modeApiKey() {
      switch (this.mode) {
        case 0:
          return 'manual_add';
        case 1:
          return 'manual_reduce';
        case 2:
          return 'freeze';
        default:
          return '';
      }
    }
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    },
    isViewFreezeHistory(v) {
      if (v) {
        this.fetchFreezeHistory();
      }
    }
  },

  methods: {
    init() {
      this.$set(this, 'localData', {
        ...schema,
        ...this.passData
      });
      this.$refs.form && this.$refs.form.reset();

      this.fetchFunds();
    },
    reset() {
      this.$set(this, 'localData', {});
      this.$refs.form && this.$refs.form.resetValidation();
    },
    close() {
      this.$emit('close');
    },
    async submit() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;
      const passdata = { ...this.form };

      if (this.modeApiKey !== 'freeze') {
        delete passdata.to_unfreeze_at;
      }

      const Capital = await addCapitalLog({
        merchant_id: this.localData.id,
        action: this.modeApiKey,
        ...passdata
      });
      if (!Capital.error || Capital.success) {
        this.$emit('save');
        this.$toast('保存成功');
        this.close();
      } else {
        this.$toast.error('保存失敗');
      }

      this.loading = false;
    },

    async fetchFunds() {
      this.isFetching = true;
      const { name, amt, frozen_amt, error } = await checkCapital(
        this.localData.id
      );
      if (!error) {
        this.localData.amt = amt;
        this.localData.frozen_amt = frozen_amt;
      }
      this.isFetching = false;
    },

    async fetchFreezeHistory(condition = this.condition) {
      this.freezeHistory.loading = true;
      const History = await getFrozenOrders({
        ...condition,
        query: this.localData.serial
      });
      if (!History.error) {
        this.freezeHistory.total = History.total;
        this.freezeHistory.page = History.page;
        this.$set(this.freezeHistory, 'items', History.items);
      }
      this.freezeHistory.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
