<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card min-height="500">
      <v-card-title class="pos-r">
        重置谷歌密鑰
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-window v-model="step">
          <SizeBox height="48" />
          <v-window-item :value="0">
            <v-form ref="form">
              <Async
                :loading="loading"
                :class="loading ? ['w100p', 'flex-center'] : null"
              >
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      rounded
                      label="輸入管理員密碼"
                      placeholder="輸入密碼"
                      v-model="password"
                      hint="先驗證為管理員後，才可查看商戶密鑰"
                      :rules="[$rules.required]"
                      type="password"
                      persistent-hint
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </Async>
            </v-form>
          </v-window-item>
          <v-window-item :value="1">
            <div class="fill-all flex-center text-DisplaySmall">
              <div class="w60p">
                <v-card-text>
                  <v-form ref="bind_otp_form" @submit.prevent="connectOtp">
                    <v-img :src="secret.data_url"></v-img>
                    <v-text-field
                      rounded
                      label="Google 驗證碼"
                      v-model="otpCode"
                      :rules="[$rules.required]"
                      outlined
                    ></v-text-field>
                    <SizeBox height="12" />
                  </v-form>
                </v-card-text>
              </div>
              <SizeBox height="12" />
            </div>
          </v-window-item>
        </v-window>
      </v-card-text>

      <v-card-actions>
        <v-btn
          rounded
          color="Secondary100"
          depressed
          large
          @click="close"
          :disabled="loading"
        >
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          v-if="step === 0"
          color="primary"
          dark
          depressed
          large
          @click="submit"
          :loading="loading"
        >
          下一步
        </v-btn>
        <v-btn
          rounded
          v-if="step === 1"
          color="primary"
          dark
          depressed
          large
          @click="connectOtp"
        >
          建立安全驗證
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { resetMerchantApiSecretPw } from '@/api/merchants';
import {
  checkPassword,
  enableOTP,
  resetSecret,
  resetSecretPw
} from '@/api/2fa';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({
        /**
         * mode: String, // new, edit
         */
      })
    }
  },

  data() {
    return {
      loading: false,
      password: '',
      step: 0,
      otpCode: '',
      secret: {
        auth_url: '',
        data_url: ''
      }
    };
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    }
  },

  methods: {
    init() {
      this.$refs.form && this.$refs.form.reset();
    },
    reset() {
      this.$refs.form && this.$refs.form.reset();
      this.loading = false;
      this.password = '';
      this.step = 0;
    },
    close() {
      this.$emit('close');
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      const Checked = await checkPassword({
        account: this.$store.getters['auth/getUser'].account,
        password: this.password
      });

      if (Checked.error) {
        this.$toast.error('密碼錯誤');
        return;
      }

      const { auth_url, data_url, error } = await resetSecret(
        this.passData.user.id
      );

      if (!error) {
        this.$toast('建立成功');
        this.secret.auth_url = auth_url;
        this.secret.data_url = data_url;
        this.step++;
      } else {
        this.$toast.error('建立失敗');
      }
    },
    async connectOtp() {
      const { success, error } = await enableOTP({
        account: this.passData.account,
        otp_code: this.otpCode
      });

      if (success) {
        this.$emit('save');
        this.$toast('重置成功');
        this.close();
      } else {
        this.$toast.error('重置失敗');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
