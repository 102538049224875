<template>
  <!-- eslint-disable  -->
  <div>
    <v-card flat class="w100p">
      <!--  -->
    </v-card>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
      <v-spacer></v-spacer>
    </div>

    <FilterBox
      :open.sync="isFilterOpen"
      v-model="filter"
      :loading="isLoading"
      :options="filterOptions"
      @search="fetchData"
    />

    <v-card
      outlined
      :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
    >
      <v-card-text class="d-none d-sm-flex align-center flex-wrap pb-0 pb-sm-4">
        <v-dialog v-model="isInfoOpen" v-if="$vuetify.breakpoint.smAndDown">
          <v-card>
            <v-card-text class="py-3">
              商戶： 共有 {{ result.list.length }} 個
              <br />
              代理商： 共 {{ result.header_info.total_agent || 0 }} 個
              <br />
              可提現金額： {{ result.header_info.total_amt || 0 }} 元
              <br />
              凍結金額： {{ result.header_info.total_frozen_amt || 0 }} 元
            </v-card-text>
          </v-card>
        </v-dialog>
        <div v-else class="d-flex align-center flex-wrap mb-4 mb-sm-0">
          商戶： 共有 {{ result.list.length }} 個
          <v-divider vertical class="mx-3"></v-divider>
          代理商： 共 {{ result.header_info.total_agent || 0 }} 個
          <v-divider vertical class="mx-3"></v-divider>
          可提現金額： {{ result.header_info.total_amt || 0 }} 元
          <v-divider vertical class="mx-3"></v-divider>
          凍結金額： {{ result.header_info.total_frozen_amt || 0 }} 元
        </div>

        <v-spacer></v-spacer>

        <v-btn
          rounded
          color="primary"
          dark
          depressed
          @click="openForm('Info', null, 'new')"
          :disabled="!$getGrantValue('merchant_list.add')"
        >
          新增商戶
        </v-btn>
        <SizeBox width="12" />
        <ExportBtn
          :items="result.list"
          :headers="headers"
          :file-name="`${
            $store.getters['app/getRouteTitle']
          } ${$moment().format('YYYY-MM-DD_H:mm:ss')}`"
          sheet-name="sheetName"
          :exclude="['keys', 'actions']"
        />
      </v-card-text>

      <DataTable
        :list="result.list"
        :fields="headers"
        :total="result.total"
        :replace="[
          'serial',
          'status',
          'enabled_collect',
          'merchant_type_id',
          'merchant',
          'amt',
          'prepaid_fee',
          'keys',
          'actions'
        ]"
        :loading="isLoading"
        @get-list="fetchData"
      >
        <template #item.serial="{ item }">
          <v-chip>{{ item.serial }}</v-chip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon
                v-on="on"
                color="merchant"
                @click="simulateMerchant(item)"
              >
                mdi-arrow-top-right-bold-box
              </v-icon>
            </template>

            <span>模擬商戶</span>
          </v-tooltip>
        </template>
        <template #item.status="{ item }">
          <div class="flex-center">
            <v-switch
              color="Primary"
              :input-value="item.status"
              @change="changeStatus(item)"
              :disabled="
                item.isStatusChanging || !$getGrantValue('merchant_list.status')
              "
              inset
            ></v-switch>
          </div>
        </template>
        <template #item.enabled_collect="{ item }">
          <div class="flex-center">
            <v-switch
              color="Primary"
              :input-value="item.enabled_collect"
              @change="changeCharge(item)"
              :disabled="
                item.isChargeChanging ||
                !$getGrantValue('merchant_list.collect_order')
              "
              inset
            ></v-switch>
          </div>
        </template>
        <template #item.merchant_type_id="{ item }">
          {{ parseType(item) }}
        </template>
        <template #item.amt="{ item }">
          <div class="d-flex justify-end">
            <v-btn-toggle x-small outlined dense rounded>
              <v-btn
                :disabled="!$getGrantValue('merchant_list.capital_management')"
                @click="openForm('Fund', item)"
              >
                {{ item.amt | currency }}
              </v-btn>

              <v-btn
                :disabled="!$getGrantValue('merchant_list.capital_management')"
                @click="openForm('Fund', item)"
              >
                調整資金
              </v-btn>
            </v-btn-toggle>
          </div>
        </template>

        <template #item.prepaid_fee="{ item }">
          <div class="d-flex justify-end">
            <v-tooltip top :disabled="item.is_prepaid_fee_enough">
              <template #activator="{ on }">
                <div v-on="on">
                  <v-btn-toggle x-small outlined dense rounded>
                    <v-btn
                      :disabled="
                        !$getGrantValue('merchant_list.capital_management')
                      "
                      @click="openForm('PrepaidFee', item)"
                      :color="item.is_prepaid_fee_enough ? null : 'Error400'"
                    >
                      {{ item.prepaid_fee | currency }}
                    </v-btn>

                    <v-btn
                      :disabled="
                        !$getGrantValue('merchant_list.capital_management')
                      "
                      @click="openForm('PrepaidFee', item)"
                      :color="item.is_prepaid_fee_enough ? null : 'Error400'"
                    >
                      手續費設定
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </template>
              <span>預付手續費不足</span>
            </v-tooltip>
          </div>
        </template>

        <template #item.merchant="{ item }">
          {{ item.merchant && item.merchant.name }}
        </template>

        <template #item.keys="{ item }">
          <div class="d-flex flex-wrap">
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  v-on="on"
                  color="Secondary100"
                  rounded
                  :small="$vuetify.breakpoint.smAndDown"
                >
                  操作
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list rounded>
                <v-list-item
                  v-for="(key, i) in item.keys"
                  :key="`key:${item.id} ${i}`"
                  @click="
                    key.action && handleAction((vm) => key.action(vm, item))
                  "
                  :disabled="key.grant ? !$getGrantValue(key.grant) : false"
                >
                  <v-icon v-if="!!key.icon" size="18" class="mr-1">
                    {{ key.icon }}
                  </v-icon>
                  {{ key.label }}
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
        <template #item.actions="{ item }">
          <div class="d-flex flex-wrap">
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  v-on="on"
                  color="Secondary100"
                  rounded
                  :small="$vuetify.breakpoint.smAndDown"
                >
                  操作
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list rounded>
                <v-list-item
                  v-for="(key, i) in item.actions"
                  :key="`key:${item.id} ${i}`"
                  @click="
                    key.action && handleAction((vm) => key.action(vm, item))
                  "
                  :disabled="key.grant ? !$getGrantValue(key.grant) : false"
                >
                  <v-icon v-if="!!key.icon" size="18" class="mr-1">
                    {{ key.icon }}
                  </v-icon>
                  {{ key.label }}
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </DataTable>
    </v-card>

    <InfoForm
      :value="showingForm === 'Info'"
      :merchat-types="types"
      @close="showingForm = null"
      @save="fetchData"
      :passData="passData"
    />

    <FundForm
      :value="showingForm === 'Fund'"
      @close="showingForm = null"
      @save="fetchData"
      :passData="passData"
    />

    <ResetGoogleAuthForm
      :value="showingForm === 'ResetGoogleAuth'"
      @close="showingForm = null"
      @save="fetchData"
      :passData="passData"
    />

    <ViewKeyForm
      :value="showingForm === 'ViewKey'"
      @close="showingForm = null"
      :passData="passData"
    />

    <ResetMerchantKeyForm
      :value="showingForm === 'ResetMerchantKey'"
      @close="showingForm = null"
      @save="fetchData"
      :passData="passData"
    />

    <FeeForm
      :value="showingForm === 'FundSet'"
      @close="showingForm = null"
      @save="fetchData"
      :passData="passData"
    />

    <ChannelSetForm
      :value="showingForm === 'ChannelSet'"
      @close="showingForm = null"
      @save="fetchData"
      :passData="passData"
    />

    <CollectRuleForm
      :value="showingForm === 'CollectRule'"
      @close="showingForm = null"
      @save="fetchData"
      :passData="passData"
    />

    <CollectForm
      :value="showingForm === 'Collect'"
      @close="showingForm = null"
      @save="fetchData"
      :passData="passData"
    />

    <PayRuleForm
      :value="showingForm === 'PayRule'"
      @close="showingForm = null"
      @save="fetchData"
      :passData="passData"
    />

    <PrepaidFeeForm
      :value="showingForm === 'PrepaidFee'"
      @close="showingForm = null"
      @save="fetchData"
      :passData="passData"
    />

    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    >
      <v-list-item
        v-if="$getGrantValue('merchant_list.add')"
        @click="openForm('Info', null, 'new')"
      >
        <v-icon>mdi-plus</v-icon>
        <span class="text-no-wrap">新增商戶</span>
      </v-list-item>
    </FunctionFab>
  </div>
</template>

<script>
import { getMerchantTypes } from '@/api/merchantType';
import {
  getMerchants,
  removeMerchant,
  editMerchantStatus,
  editMerchantEnabledCollect,
  mockMerchant
} from '@/api/merchants';

import InfoForm from '@/components/merchant/list/InfoForm.vue';
import FundForm from '@/components/merchant/list/FundForm.vue';
import ResetGoogleAuthForm from '@/components/merchant/list/ResetGoogleAuthForm.vue';
import ViewKeyForm from '@/components/merchant/list/ViewKeyForm.vue';
import ResetMerchantKeyForm from '@/components/merchant/list/ResetMerchantKeyForm.vue';
import FeeForm from '@/components/merchant/list/FeeForm.vue';
import ChannelSetForm from '@/components/merchant/list/ChannelSetForm.vue';
import CollectRuleForm from '@/components/merchant/list/CollectRuleForm.vue';
import PayRuleForm from '@/components/merchant/list/PayRuleForm.vue';
import CollectForm from '@/components/merchant/list/CollectForm.vue';
import PrepaidFeeForm from '@/components/merchant/list/PrepaidFeeForm.vue';

const keys = [
  {
    label: '查看',
    icon: 'mdi-eye-outline',
    grant: 'merchant_list.password_management',
    action: (vm, v) => {
      vm.openForm('ViewKey', v);
    }
  },
  {
    label: '重置谷歌密鑰',
    icon: 'mdi-cached',
    grant: 'merchant_list.password_management',
    action: (vm, v) => {
      vm.openForm('ResetGoogleAuth', v);
    }
  },
  {
    label: '重置商戶密鑰',
    icon: 'mdi-cached',
    grant: 'merchant_list.password_management',
    action: (vm, v) => {
      vm.openForm('ResetMerchantKey', v);
    }
  }
];
const actions = [
  {
    label: '提單',
    icon: 'mdi-file-document-outline',
    grant: 'merchant_list.place_order',
    action: (vm, v) => {
      vm.openForm('Collect', v);
    }
  },
  {
    label: '編輯',
    icon: 'mdi-pencil-outline',
    grant: 'merchant_list.edit',
    action: (vm, v) => {
      vm.openForm('Info', v, 'edit');
    }
  },
  {
    label: '通道配置',
    icon: 'mdi-transit-detour',
    action: (vm, v) => {
      vm.openForm('ChannelSet', v);
    }
  },
  {
    label: '費率設定',
    icon: 'mdi-currency-usd',
    grant: 'merchant_list.fee_percent_setting',
    action: (vm, v) => {
      vm.openForm('FundSet', v);
    }
  },
  {
    label: '代收風控',
    icon: 'mdi-transit-detour',
    grant: 'merchant_list.collect_rule',
    action: (vm, v) => {
      vm.openForm('CollectRule', v);
    }
  },
  {
    label: '代付風控',
    icon: 'mdi-transit-detour',
    grant: 'merchant_list.pay_rule',
    action: (vm, v) => {
      vm.openForm('PayRule', v);
    }
  },
  {
    label: '刪除',
    icon: '',
    grant: 'merchant_list.remove',
    action: (vm, v) => {
      vm.$confirm({
        title: '刪除',
        content: '刪除之後，資料將無法復原，您確定要刪除？',
        btn: {
          color: 'error',
          text: '刪除'
        },
        callback: async () => {
          await removeMerchant({ id: v.id });
          vm.$toast('刪除成功');
          vm.fetchData();
        }
      });
    }
  }
];

export default {
  components: {
    InfoForm,
    FundForm,
    ResetGoogleAuthForm,
    ViewKeyForm,
    ResetMerchantKeyForm,
    FeeForm,
    ChannelSetForm,
    CollectRuleForm,
    PayRuleForm,
    CollectForm,
    PrepaidFeeForm
  },
  data() {
    return {
      ...this.$clone(this.$commonSchema),
      headers: [
        { text: '商戶編號', value: 'serial', align: 'center' },
        { text: '商戶名稱', value: 'name', align: 'center' },
        // { text: '登入帳號', value: 'account', align: 'center',  },
        { text: '用戶狀態', value: 'status', align: 'center' },
        {
          text: '儲值功能',
          value: 'enabled_collect',
          align: 'center'
        },
        {
          text: '商戶類型',
          value: 'merchant_type_id',
          align: 'center'
        },
        {
          text: '所屬代理',
          value: 'merchant',
          align: 'center'
        },
        { text: '商戶資金', value: 'amt', align: 'center' },
        { text: '預付手續費', value: 'prepaid_fee', align: 'center' },
        { text: '密鑰管理', value: 'keys', align: 'center' },
        // {
        //   text: '最近登入時間',
        //   value: 'last_login',
        //   align: 'center',
        //
        // },
        { text: '操作', value: 'actions', align: 'center' }
      ],
      types: [],
      filterOptions: [
        {
          label: '商戶類型',
          type: 'autocomplete',
          name: 'merchant_type_id',
          query: 'type',
          api: getMerchantTypes
        },
        {
          label: '商戶名稱',
          type: 'text',
          name: 'query'
        }
      ]
    };
  },

  async created() {
    const Types = await getMerchantTypes();
    if (!Types.error) {
      this.types = Types.items.map(({ id, name }) => ({
        value: id,
        text: name
      }));
    }

    const type = this.$route.query.type;
    if (type) {
      this.filter.merchant_type_id = type;
    }

    this.fetchData();
  },

  methods: {
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;
      const Merchants = await getMerchants({
        ...this.filter,
        ...condition
      });
      if (!Merchants.error) {
        this.result.total = Merchants.total;
        this.result.page = Merchants.page;
        this.result.header_info = Merchants.header_info;
        this.$set(
          this.result,
          'list',
          Merchants.items.map((item) => ({
            ...item,
            keys,
            actions
          }))
        );
      }

      this.isLoading = false;
    },
    openForm(name, data = {}, mode = '') {
      this.showingForm = name;

      this.$set(this, 'passData', {
        mode,
        ...data
      });
    },
    handleAction(fn) {
      fn(this);
    },

    parseType(v) {
      const target = this.types.find(
        ({ value }) => value === v.merchant_type_id
      );
      return (target && target.text) || '';
    },

    async changeStatus(item) {
      item.isStatusChanging = true;
      const { error } = await editMerchantStatus({
        id: item.id,
        status: !item.status
      });

      if (error) {
        return;
      } else {
        item.status = !item.status;
      }

      item.isStatusChanging = false;

      return error;
    },
    async changeCharge(item) {
      item.isChargeChanging = true;
      const { error } = await editMerchantEnabledCollect({
        id: item.id,
        enabled_collect: !item.enabled_collect
      });

      if (error) {
        return;
      } else {
        item.enabled_collect = !item.enabled_collect;
      }

      item.isChargeChanging = false;

      return error;
    },

    simulateMerchant(merchant) {
      const mrId = localStorage.getItem('mrId');

      if (mrId) {
        if (mrId === merchant.id) {
          window.open(`${location.origin}/vip/`);
        } else {
          this.$confirm({
            title: '模擬商戶',
            content: `目前已有正在模擬商戶 是否覆蓋模擬`,
            btn: {
              color: 'primary',
              text: '覆蓋模擬'
            },
            callback: () => {
              this.doMock(merchant.id);
            }
          });
        }
      } else {
        if (merchant.id) {
          this.doMock(merchant.id);
        }
      }
    },

    async doMock(mrId) {
      const { error } = await mockMerchant();
      if (error) {
        this.$toast.error('模擬失敗');
        return;
      }
      localStorage.setItem('mrId', mrId);
      window.open(`${location.origin}/vip/`);
      this.$store.dispatch('auth/FETCH_MERCHAT');
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
