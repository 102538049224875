<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card>
      <v-card-title class="pos-r">
        {{ modeText }}
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <SizeBox height="48" />
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <v-text-field
                rounded
                label="商戶編號"
                hint="系統自動生成商戶編號"
                persistent-hint
                :value="localData.serial"
                readonly
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                rounded
                label="商戶名稱"
                placeholder="輸入商戶名稱"
                v-model="form.name"
                :rules="[$rules.required]"
                :disabled="readonly"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                rounded
                label="登入帳號"
                placeholder="輸入登入帳號"
                v-model="form.account"
                :rules="[$rules.required]"
                :disabled="readonly"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-select
                rounded
                label="商戶類型"
                placeholder="請選擇"
                v-model="form.merchant_type_id"
                :items="merchatTypes"
                :rules="[$rules.required]"
                :disabled="readonly"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="6">
              <c-autocomplete
                rounded
                label="所屬代理"
                placeholder="輸入以搜尋商戶"
                no-data-text="找不到所屬代理"
                v-model="form.merchant_id"
                :api="getAgents"
                :exclude="passData ? [passData.id] : null"
              ></c-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-combobox
                rounded
                label="登入 IP 白名單"
                placeholder="輸入登入 IP 白名單"
                v-model="form.whitelisted_login_ips"
                :disabled="readonly"
                small-chips
                multiple
                outlined
              ></v-combobox>
            </v-col>

            <v-col cols="12">
              <v-combobox
                rounded
                label="代收 IP 白名單"
                v-model="form.whitelisted_collect_ips"
                :disabled="readonly"
                small-chips
                multiple
                outlined
              ></v-combobox>
            </v-col>
            <v-col cols="12">
              <v-combobox
                rounded
                label="代收域名白名單"
                v-model="form.whitelisted_collect_domains"
                :disabled="readonly"
                small-chips
                multiple
                outlined
              ></v-combobox>
            </v-col>
            <v-col cols="12">
              <v-combobox
                rounded
                label="代付 IP 白名單"
                v-model="form.whitelisted_pay_ips"
                :disabled="readonly"
                small-chips
                multiple
                outlined
              ></v-combobox>
            </v-col>
            <v-col cols="12">
              <v-combobox
                rounded
                label="代付域名白名單"
                v-model="form.whitelisted_pay_domains"
                :disabled="readonly"
                small-chips
                multiple
                outlined
              ></v-combobox>
            </v-col>

            <v-col cols="auto">
              <v-switch
                color="primary"
                v-model="form.status"
                label="狀態"
                :disabled="readonly"
                inset
              ></v-switch>
            </v-col>

            <v-col cols="auto">
              <v-switch
                color="primary"
                v-model="form.enabled_pay_api"
                label="代付 API 連接狀態"
                :disabled="readonly"
                inset
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <v-switch
                color="primary"
                v-model="form.enabled_collect"
                label="儲值功能"
                :disabled="readonly"
                inset
              ></v-switch>
            </v-col>

            <v-col cols="auto">
              <v-switch
                color="primary"
                v-model="form.enabled_pay_merchant"
                label="提現功能"
                :disabled="readonly"
                inset
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn rounded color="Secondary100" depressed large @click="close">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="primary"
          dark
          depressed
          large
          :loading="loading"
          @click="submit"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { addMerchant, editMerchant, getAgents } from '@/api/merchants';
import schema from './schema';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({
        /**
         * mode: String, // new, edit
         */
      })
    },
    merchatTypes: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      getAgents,

      loading: false,
      localData: {},
      form: {
        id: '',
        account: '',
        name: '',
        merchant_type_id: '',
        merchant_id: '',
        amt: 0,
        status: true,
        enabled_collect: true,
        enabled_pay_merchant: true,
        enabled_pay_api: true,
        enabled_whitelisted_login_ips: true,
        whitelisted_login_ips: [],
        whitelisted_collect_ips: [],
        whitelisted_collect_domains: [],
        whitelisted_pay_ips: [],
        whitelisted_pay_domains: []
      }
    };
  },

  computed: {
    modeText() {
      switch (this.localData.mode) {
        case 'new':
          return '新增商戶';
        case 'edit':
          return '編輯商戶';
        case 'view':
          return '檢視商戶';
        default:
          return '新增商戶';
      }
    },
    isNew() {
      return !this.localData.mode || this.localData.mode === 'new';
    },
    readonly() {
      return this.localData.mode === 'view';
    }
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    }
  },

  methods: {
    init() {
      this.$set(this, 'localData', { ...schema, ...this.passData });
      this.$set(this, 'form', { ...schema, ...this.passData });
      this.$refs.form && this.$refs.form.resetValidation();
    },
    reset() {
      const originData = this.$options.data();
      Object.keys(originData).forEach((key) => {
        this.$set(this.$data, key, originData[key]);
      });
      this.$refs.form.reset();
    },
    close() {
      this.$emit('close');
    },
    async submit() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;
      const isNew = this.localData.mode === 'new';
      const firedApi = isNew ? addMerchant : editMerchant;
      const passdata = { ...this.form };

      if (isNew) {
        delete passdata.id;
        delete passdata.serial;
      }

      if (!passdata.merchant_id) {
        delete passdata.merchant_id;
      }

      const Merchant = await firedApi(passdata);
      if (!Merchant.error) {
        this.$emit('save');
        this.$toast('保存成功');
        this.close();
      } else {
        this.$toast.error('保存失敗');
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
