<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card>
      <v-card-title class="pos-r">
        通道配置
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <Async :loading="isFetching">
          <v-form ref="form">
            <SizeBox height="48" />
            <div class="d-flex flex-wrap">
              <v-select
                rounded
                label="通道類型"
                v-model="channelType"
                :items="channelTypeOptions"
                :rules="[$rules.required]"
                outlined
              ></v-select>
              <SizeBox width="24" />
              <v-switch
                label="通道狀態"
                v-model="form.status"
                inset
                outlined
              ></v-switch>
            </div>

            <v-row>
              <v-col cols="12">
                <v-dialog max-width="800" scrollable>
                  <template #activator="{ on }">
                    <v-text-field
                      rounded
                      v-on="on"
                      label="指定通道"
                      placeholder="請選擇"
                      :value="channelMode | toAssingTypeText"
                      :rules="[$rules.required]"
                      :error-messages="pickChannelError"
                      @blur="validatePicked"
                      @focus="pickChannelError = null"
                      append-icon="mdi-menu-down"
                      readonly
                      outlined
                    />
                  </template>

                  <v-card>
                    <v-card-title>
                      <v-tabs v-model="channelModeIdx" centered>
                        <v-tab>單獨</v-tab>
                        <v-tab>輪詢</v-tab>
                      </v-tabs>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-row align="center" justify="space-between">
                            <v-col cols="auto">
                              <v-checkbox
                                hide-details
                                disabled
                                class="ma-0 opacity--0"
                              ></v-checkbox>
                            </v-col>
                            <v-col class="flex-center">通道代碼</v-col>
                            <v-col class="flex-center">通道名稱</v-col>
                            <v-col class="flex-center">
                              <span
                                :class="{ 'd-none': channelMode === 'single' }"
                              >
                                權重
                              </span>
                            </v-col>
                          </v-row>

                          <v-radio-group
                            v-show="channelMode === 'single'"
                            v-model="pickedChannel"
                          >
                            <v-row
                              v-for="(option, i) in parsedCheckedChannel"
                              :key="`channel option${i}`"
                              align="center"
                              justify="space-between"
                            >
                              <v-col cols="auto">
                                <v-radio
                                  hide-details
                                  :value="option"
                                  :input-value="option.value"
                                  class="ma-0"
                                ></v-radio>
                              </v-col>
                              <v-col class="flex-center">
                                <span>{{ i + 1 }}{{ option.value }}</span>
                              </v-col>
                              <v-col class="flex-center">
                                <span>
                                  {{ option.name }}
                                </span>
                              </v-col>
                              <v-col class="flex-center"></v-col>
                            </v-row>
                          </v-radio-group>

                          <v-radio-group v-show="channelMode === 'polling'">
                            <v-row
                              v-for="(option, i) in parsedCheckedChannel"
                              :key="`channel option${i}`"
                              align="center"
                            >
                              <v-col cols="auto">
                                <v-checkbox
                                  hide-details
                                  v-model="pickedChannels"
                                  :value="option"
                                  class="ma-0"
                                ></v-checkbox>
                              </v-col>
                              <v-col class="flex-center">
                                <span>{{ i + 1 }}{{ option.value }}</span>
                              </v-col>
                              <v-col class="flex-center">
                                <span>
                                  {{ option.name }}
                                </span>
                              </v-col>
                              <v-col class="flex-center">
                                <input
                                  v-model="option.priority"
                                  @change="
                                    (option.priority > 9 &&
                                      (option.priority = 9)) ||
                                      (option.priority < 1 &&
                                        (option.priority = 1))
                                  "
                                  type="number"
                                  min="1"
                                  max="9"
                                  class="priority_input"
                                />
                              </v-col>
                            </v-row>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-dialog>

                <v-card
                  v-if="channelMode === 'single'"
                  flat
                  color="Secondary100"
                  :class="{
                    'opacity--0': !pickedChannel
                  }"
                >
                  <v-card-text>
                    <v-chip v-if="!pickedChannel">
                      <!-- placeholder -->
                    </v-chip>
                    <v-chip
                      v-if="pickedChannel"
                      color="Surface500"
                      @click:close="pickedChannel = null"
                      label
                      close
                    >
                      {{ pickedChannel.name }}
                    </v-chip>
                  </v-card-text>
                </v-card>

                <v-card
                  v-else-if="channelMode === 'polling'"
                  flat
                  color="Secondary100"
                  :class="{
                    'opacity--0': !pickedChannels.length
                  }"
                >
                  <v-card-text>
                    <v-chip v-if="!pickedChannels.length">
                      <!-- placeholder -->
                    </v-chip>

                    <v-chip
                      v-for="(item, i) in pickedChannels"
                      :key="`picked channel:${i}`"
                      color="Surface500"
                      @click:close="pickedChannels.splice(i, 1)"
                      label
                      close
                    >
                      {{ item.name }}
                    </v-chip>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
          <SizeBox height="120" />
        </Async>
      </v-card-text>

      <v-card-actions>
        <v-btn rounded color="Secondary100" depressed large @click="close">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="primary"
          dark
          depressed
          large
          @click="submit"
          :loading="loading"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getCollectChannelTypes } from '@/api/collectChannels';
import {
  editCollectChannelSettings,
  getCollectChannelSettings
} from '@/api/merchants';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({
        /**
         * freezingFund: Number
         */
      })
    }
  },
  filters: {
    toAssingTypeText(v) {
      switch (v) {
        case 'single':
          return '單獨';
        case 'polling':
          return '輪詢';
        default:
          return '';
      }
    }
  },

  data() {
    return {
      loading: false,
      isFetching: false,
      channelStatus: true,
      channelModeIdx: 0,
      channelMode: 'single',
      channelType: '',
      channelTypeOptions: [],
      pickChannelError: null,
      pickedChannels: [],
      pickedChannel: null,

      channels: [
        /**
         * {
         *   id: '',
         *   name: '',
         *   code: ''
         * }
         */
      ],
      channelSettings: [
        /**
         * {
         *   id: '',
         *   merchant_id: '',
         *   assign_type: '',
         *   status: true,
         *   created_at: '',
         *   updated_at: '',
         *   collect_channel_type: {
         *     id: '',
         *     name: '',
         *     code: ''
         *   },
         *   polling_channels: [
         *     {
         *       id: '',
         *       priority: 1,
         *       collect_channel: {
         *         id: '',
         *         name: '',
         *         code: ''
         *       }
         *     }
         *   ]
         * }
         */
      ],
      parsedCheckedChannel: [],

      form: {
        id: '',
        merchant_id: '',
        polling_channels: [
          /**
           * {
           *   priority: 1,
           *   collect_channel_id: ''
           * }
           */
        ],
        assign_type: '', // single, polling
        status: false
      }
    };
  },

  computed: {
    currentSettingChannel() {
      return (
        this.channelSettings.find(
          ({ collect_channel_type }) =>
            collect_channel_type && collect_channel_type.id === this.channelType
        ) || { polling_channels: [] }
      );
    }
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    },
    currentSettingChannel(v) {
      this.channelMode = v.assign_type;
      this.form.status = !!v.status;
      this.form.id = v.id;

      this.parsedCheckedChannel = this.channels.map((channel) => {
        const target = this.currentSettingChannel.polling_channels.find(
          ({ collect_channel }) => channel.id === collect_channel.id
        );
        const isChecked = !!target;
        const priority = target ? target.priority || 1 : 1;
        const output = {
          ...channel,
          priority
        };

        if (v.assign_type === 'single') {
          if (isChecked) this.pickedChannel = output;
        } else if (v.assign_type === 'polling') {
          if (isChecked) this.pickedChannels.push(output);
        }
        return output;
      });
    },
    channelMode(v) {
      this.form.assign_type = v;

      switch (v) {
        case 'single':
          this.channelModeIdx = 0;
          break;
        case 'polling':
          this.channelModeIdx = 1;
          break;
        default:
          break;
      }
    },
    channelModeIdx(v) {
      switch (v) {
        case 0:
          this.channelMode = 'single';
          break;
        case 1:
          this.channelMode = 'polling';
          break;
        default:
          break;
      }
    }
  },

  methods: {
    init() {
      this.$refs.form && this.$refs.form.resetValidation();
      this.pickChannelError = null;
      this.fetchData();
    },
    reset() {
      [
        'channelTypeOptions',
        'pickedChannels',
        'channels',
        'channelSettings',
        'parsedCheckedChannel'
      ].forEach((key) => {
        this.$set(this, key, []);
      });
      this.form = {
        id: '',
        merchant_id: '',
        polling_channels: [],
        assign_type: '',
        status: false
      };
      this.$refs.form && this.$refs.form.resetValidation();
    },
    close() {
      this.$emit('close');
    },
    async fetchData() {
      this.isFetching = true;
      const { collect_channels, collect_channel_settings, error } =
        await getCollectChannelSettings({
          merchant_id: this.passData.id
        });
      if (!error) {
        this.$set(this, 'channels', collect_channels);
        this.$set(this, 'channelSettings', collect_channel_settings);
      }
      const Type = await getCollectChannelTypes();
      if (!Type.error) {
        this.channelTypeOptions = Type.map(({ id, name, code }) => ({
          text: name,
          value: id,
          code
        }));

        if (this.channelTypeOptions.length) {
          this.channelType = this.channelTypeOptions[0].value;
        }
      }
      this.isFetching = false;
    },
    validatePicked() {
      let flag = true;
      if (this.channelMode === 'single' && !this.pickedChannel) {
        flag = false;
      }
      if (this.channelMode === 'polling' && !this.pickedChannels.length) {
        flag = false;
      }

      if (!flag) {
        this.pickChannelError = '請選擇指定通道';
      }

      return flag;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      if (!this.validatePicked()) return;
      this.loading = true;

      const passData = { ...this.form };
      if (this.channelMode === 'single') {
        passData.polling_channels = [this.pickedChannel];
      } else if (this.channelMode === 'polling') {
        passData.polling_channels = this.pickedChannels;
      }

      passData.polling_channels = passData.polling_channels.map(
        ({ priority, id }) => ({
          collect_channel_id: id,
          priority: Number(priority) || 1
        })
      );

      const { success } = await editCollectChannelSettings(passData);
      if (success) {
        this.$toast('保存成功');
        this.close();
      } else {
        this.$toast.error('保存失敗');
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.priority_input {
  width: 2.5em;
  padding: 4px 0.5em;
  border: 1px solid $Secondary400 !important;
  border-radius: 4px;

  text-align: center;
}
</style>
